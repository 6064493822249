import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'

import api from '@/utils/api'

import {
  IProjectResponse,
  IProjectUpdateRequest,
  IProjectListResponse,
  ITicketResponse,
  ITicketListResponse,
  ITicket,
  IProjectCreateRequest,
  ITicketAttachment,
  IDeleteStatusRequestParams,
  IDeleteStatusResponse,
  ITicketListRequest,
  IInviteUserResponse,
  IInviteUserRequest,
} from '../models/IProject'
import { IUserListItemResponse } from '../../auth/models/IUser'
import { ICommentListResponse, ICommentRequest, ICommentResponse } from '../models/IComment'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { IProjectUser } from '../models/IUser'

export const PATHS = {
  allProjects: '/user/projects',
  projects: '/teamspace/:teamspaceId/projects/',
  projectById: '/teamspace/:teamspaceId/projects/:projectId',
  projectTickets: '/teamspace/:teamspaceId/project/:projectId/tickets/',
  myProjectUser: '/teamspace/:teamspaceId/projects/:projectId/user',
  projectUsers: '/teamspace/:teamspaceId/projects/:projectId/users',
  projectUser: '/teamspace/:teamspaceId/projects/:projectId/users/:userId',
  invite: '/teamspace/:teamspaceId/projects/:projectId/invite',
  ticketById: '/teamspace/:teamspaceId/project/:projectId/tickets/:ticketId',
  projectTicketAttachment: '/teamspace/:teamspaceId/project/:projectId/tickets/:ticketId/attachments/',
  attachment: '/teamspace/:teamspaceId/project/:projectId/tickets/:ticketId/attachments/:attachmentId',
  deleteStatus: '/teamspace/:teamspaceId/projects/:projectId/delete_status/',
  projectTicketComments: '/teamspace/:teamspaceId/project/:projectId/tickets/:ticketId/comments/',
  projectTicketCommentById: '/teamspace/:teamspaceId/project/:projectId/tickets/:ticketId/comments/:commentId',
  remind: '/teamspace/:teamspaceId/project/:projectId/tickets/remind/:ticketId',
}

export const enum KEYS {
  project = 'project',
  projects = 'projects',
  board = 'board',
  ticketList = 'ticketList',
  userList = 'userList',
  ticket = 'ticket',
  statuses = 'statuses',
  fileList = 'fileList',
  comments = 'comments',
}

export const useGetProject = (id?: string, teamspaceId?: string) => {
  return useSuspenseQuery<IProjectResponse, AxiosError>({
    queryKey: [KEYS.project, id],
    queryFn: async () => {
      const response = await api.get<IProjectResponse>(
        PATHS.projectById.replace(':projectId', id || '').replace(':teamspaceId', teamspaceId || ''),
      )

      return response.data
    },
  })
}

export const useGetProjects = (teamspaceId?: string) => {
  return useSuspenseQuery<IProjectListResponse, AxiosError>({
    queryKey: [KEYS.projects],
    queryFn: async () => {
      const response = await api.get<IProjectListResponse>(PATHS.projects.replace(':teamspaceId', teamspaceId || ''))
      return response.data
    },
  })
}

export const useGetAllProjects = () => {
  return useSuspenseQuery<IProjectListResponse, AxiosError>({
    queryKey: [KEYS.projects],
    queryFn: async () => {
      const response = await api.get<IProjectListResponse>(PATHS.allProjects)
      return response.data.filter((project) => !project.deleted_at)
    },
  })
}

export const useCreateProject = (teamspaceId?: string, invalidate: boolean = true) => {
  const queryClient = useQueryClient()
  return useMutation<IProjectResponse, AxiosError, IProjectCreateRequest>({
    mutationFn: async (body) => {
      const response = await api.post<IProjectResponse>(
        PATHS.projects.replace(':teamspaceId', body.teamspace_id || teamspaceId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      if (invalidate) {
        queryClient.invalidateQueries({ queryKey: [KEYS.projects] })
      }
    },
  })
}

export const useUpdateProject = (id?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<IProjectResponse, AxiosError, Partial<IProjectUpdateRequest>>({
    mutationFn: async (body) => {
      const response = await api.patch<IProjectResponse>(
        PATHS.projectById.replace(':projectId', id || '').replace(':teamspaceId', teamspaceId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.project, id] })
    },
  })
}

export const useDeleteProject = (id?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<IProjectListResponse, AxiosError, undefined>({
    mutationFn: async () => {
      const response = await api.delete<IProjectListResponse>(
        PATHS.projectById.replace(':projectId', id || '').replace(':teamspaceId', teamspaceId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.projects] })
    },
  })
}

export const useDeleteStatus = (projectId?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<IDeleteStatusResponse, AxiosError, IDeleteStatusRequestParams>({
    mutationFn: async (data) => {
      const response = await api.delete(
        PATHS.deleteStatus.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        { params: data },
      )
      return response.data
    },
    onSuccess: (_, params) => {
      if (!params.safe) {
        queryClient.invalidateQueries({ queryKey: [KEYS.project, projectId] })
      }
    },
  })
}

export const useGetTicketList = (projectId?: string, teamspaceId?: string, params?: ITicketListRequest) => {
  const key = params
    ? new URLSearchParams(
        Object.entries(params).map(([key, value]: [string, string[] | boolean]) => [
          key,
          String(Array.isArray(value) ? value.sort() : value),
        ]),
      ).toString()
    : 'list'

  return useQuery<ITicketListResponse, AxiosError>({
    queryKey: [KEYS.ticketList, projectId, key],
    staleTime: 3000,
    queryFn: async () => {
      const response = await api.get<ITicketListResponse>(
        PATHS.projectTickets.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        {
          params,
          paramsSerializer: {
            indexes: null,
          },
        },
      )
      return response.data
    },
  })
}

export const useGetTicket = (ticketId?: string, teamspaceId?: string, projectId?: string) => {
  return useQuery<ITicketResponse | undefined, AxiosError>({
    queryKey: [KEYS.ticket, ticketId],
    staleTime: 0,
    enabled: !!ticketId && !!teamspaceId && !!projectId,
    queryFn: async () => {
      const response = await api.get<ITicketResponse>(
        PATHS.ticketById
          .replace(':projectId', projectId || '')
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':ticketId', ticketId || ''),
      )
      return response.data
    },
  })
}

export const useCreateTicket = (teamspaceId?: string, projectId?: string) => {
  return useMutation<ITicketResponse, AxiosError, ITicket>({
    mutationFn: async (body) => {
      const response = await api.post<ITicketResponse>(
        PATHS.projectTickets.replace(':teamspaceId', teamspaceId || '').replace(':projectId', projectId || ''),
        body,
      )
      return response.data
    },
  })
}

export const usePatchTicket = (teamspaceId?: string, projectId?: string, ticketId?: string) => {
  const cache = useQueryClient()
  return useMutation<ITicketResponse, AxiosError, Partial<ITicket & { uuid: string }>>({
    mutationFn: async (body) => {
      const response = await api.patch<ITicketResponse>(
        PATHS.ticketById
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', body.uuid || ticketId || ''),
        body,
      )
      sendEvent('update-ticket')
      cache.setQueryData([KEYS.ticket, ticketId], response.data)
      return response.data
    },
  })
}

export const useDeleteTicket = (teamspaceId?: string, projectId?: string, ticketId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const response = await api.delete(
        PATHS.ticketById
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.ticketList, projectId] })
    },
  })
}

export const useGetUserList = (projectId?: string, teamspaceId?: string) => {
  return useSuspenseQuery<IUserListItemResponse[], AxiosError>({
    queryKey: [KEYS.userList, projectId],
    queryFn: async () => {
      const response = await api.get<IUserListItemResponse[]>(
        PATHS.projectUsers.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
      )
      return response.data
    },
  })
}

export const useInviteUser = (projectId?: string, teamspaceId?: string) => {
  return useMutation<IInviteUserResponse, AxiosError, IInviteUserRequest>({
    mutationFn: async (data) => {
      const response = await api.post(
        PATHS.invite.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        data,
      )
      return response.data
    },
  })
}

export const useDeleteUsers = (projectId?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (userId: string) => {
      const response = await api.delete(
        PATHS.projectUser
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':userId', userId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.userList, projectId] })
    },
  })
}

export const uploadFile = async (file: File, projectId?: string, teamspaceId?: string, ticketId?: string) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('filename', file.name)
  const response = await api.post(
    PATHS.projectTicketAttachment
      .replace(':teamspaceId', teamspaceId || '')
      .replace(':projectId', projectId || '')
      .replace(':ticketId', ticketId || ''),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return response.data
}

export const useUploadFile = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  return useMutation({
    mutationFn: async (file: File) => {
      return uploadFile(file, projectId, teamspaceId, ticketId)
    },
  })
}

export const useGetFileList = (
  projectId?: string,
  teamspaceId?: string,
  ticketId?: string,
  enabled: boolean = true,
) => {
  return useQuery<ITicketAttachment[]>({
    queryKey: [KEYS.fileList, ticketId],
    queryFn: async () => {
      const response = await api.get(
        PATHS.projectTicketAttachment
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || ''),
      )
      return response.data
    },
    enabled,
  })
}

export const useDeleteFile = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  const cache = useQueryClient()
  return useMutation<unknown, AxiosError, string>({
    mutationFn: async (attachmentId: string) => {
      const response = await api.delete(
        PATHS.attachment
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || '')
          .replace(':attachmentId', attachmentId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      cache.refetchQueries({ queryKey: [KEYS.fileList, ticketId] })
    },
  })
}

export const useGetComments = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  return useQuery<ICommentListResponse, AxiosError>({
    queryKey: [KEYS.comments, ticketId],
    queryFn: async () => {
      const response = await api.get<ICommentListResponse>(
        PATHS.projectTicketComments
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || ''),
      )
      return response.data
    },
  })
}
export const useCreateComment = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<ICommentResponse, AxiosError, ICommentRequest>({
    mutationFn: async (body) => {
      const response = await api.post<ICommentResponse>(
        PATHS.projectTicketComments
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || ''),
        {},
        { params: body },
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.comments, ticketId] })
    },
  })
}

export const useUpdateComment = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<ICommentResponse, AxiosError, ICommentRequest & { commentId: string }>({
    mutationFn: async (body) => {
      const { commentId, ...params } = body
      const response = await api.put<ICommentResponse>(
        PATHS.projectTicketCommentById
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || '')
          .replace(':commentId', commentId || ''),
        {},
        { params },
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.comments, ticketId] })
    },
  })
}

export const useDeleteComment = (projectId?: string, teamspaceId?: string, ticketId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (commentId: string) => {
      const response = await api.delete(
        PATHS.projectTicketCommentById
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || '')
          .replace(':ticketId', ticketId || '')
          .replace(':commentId', commentId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.comments, ticketId] })
    },
  })
}

export const useGetProjectUser = (projectId?: string, teamspaceId?: string) => {
  return useSuspenseQuery<IProjectUser>({
    queryKey: ['MyProjectUser'],
    queryFn: async () => {
      const response = await api.get<IProjectUser>(
        PATHS.myProjectUser.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
      )
      return response.data
    },
  })
}

export const usePatchProjectUser = (projectId?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<IProjectUser, AxiosError, Partial<IProjectUser>>({
    mutationFn: async (body) => {
      const response = await api.patch<IProjectUser>(
        PATHS.myProjectUser.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['MyProjectUser'] })
    },
  })
}

export const useRemind = (teamspaceId?: string, projectId?: string) => {
  return useMutation({
    mutationFn: async (ticketId: string) => {
      const response = await api.post(
        PATHS.remind
          .replace(':projectId', projectId || '')
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':ticketId', ticketId || ''),
      )
      return response.data
    },
  })
}
