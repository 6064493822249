import { useEffect, useRef } from 'react'
import { debounce } from '..'

export const useScrollRestorationRef = (key: string) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const savedScrollPos = JSON.parse(sessionStorage.getItem(key) || '{}') as { top: string; left: string }

    if (scrollRef.current && savedScrollPos) {
      scrollRef.current.scrollTop = parseInt(savedScrollPos.top, 10)
      scrollRef.current.scrollLeft = parseInt(savedScrollPos.left, 10)
    }

    const saveScrollPosition = () => {
      if (scrollRef.current) {
        sessionStorage.setItem(
          key,
          JSON.stringify({
            top: scrollRef.current.scrollTop.toString(),
            left: scrollRef.current.scrollLeft.toString(),
          }),
        )
      }
    }

    const saveScrollPositionDebounced = debounce(saveScrollPosition)

    scrollRef.current?.addEventListener('scroll', saveScrollPositionDebounced)

    return () => {
      scrollRef.current?.removeEventListener('scroll', saveScrollPositionDebounced)
    }
  }, [key])

  return scrollRef
}
