import { useMemo } from 'react'

import { generatePath, Outlet, useParams } from 'react-router-dom'
import TabMenu, { TabItem } from '@/components/TabMenu'

import { useTranslation } from 'react-i18next'
import routes from '@/utils/routes'
import useBackButton from '@/utils/hooks/useBackButton'

export interface TicketLayoutProps {}

export const TicketLayout: React.FC<TicketLayoutProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.tabs' })
  const { projectId, teamspaceId, ticketId } = useParams<{ projectId: string; teamspaceId: string; ticketId: string }>()

  useBackButton(teamspaceId, projectId)

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        label: t('details'),
        to: generatePath(routes.private.ticket, { projectId, teamspaceId, ticketId }),
      },

      {
        label: t('comments'),
        to: generatePath(routes.private.ticketComments, { projectId, teamspaceId, ticketId }),
      },
    ],
    [t],
  )
  return (
    <>
      <div>
        <TabMenu items={tabs} />
      </div>
      <div className="h-[calc(100%-38px)] overflow-y-auto bg-wall-main-bg-light p-3 dark:bg-wall-main-bg-dark">
        <Outlet />
      </div>
    </>
  )
}

export default TicketLayout
