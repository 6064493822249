const getFilename = (fileName: string) => fileName.replace(/\.[^/.]+$/, '')
const getExtension = (fileName: string) => {
  const parts = fileName.split('.')
  return parts.length > 1 ? parts.pop() || null : null
}

export const getFilenameParts = (fileName: string) => ({
  name: getFilename(fileName),
  ext: getExtension(fileName),
})
