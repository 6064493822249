import { Button, TextInput } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useGetProject, useUpdateProject } from '@/projects/queries'
import { CheckIcon } from '@heroicons/react/24/outline'

import { useGetMe } from '@/auth/queries'

export interface NameProps {}

type Inputs = {
  name: string
}

const MAX_TITLE_LENGTH = 70

export const Name: React.FC<NameProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.name' })
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const { data: me } = useGetMe()
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: updateProject } = useUpdateProject(projectId, teamspaceId)

  const { register, handleSubmit, formState, reset } = useForm<Inputs>({
    values: {
      name: project.name,
    },
  })
  const onSubmit = async (data: Inputs) => {
    await updateProject({ name: data.name })
    reset({ name: data.name })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative flex items-center gap-2">
      <p className="w-36 text-sm">{t('title')}</p>
      <TextInput
        {...register('name')}
        disabled={me.uuid !== project?.owner_id}
        maxLength={MAX_TITLE_LENGTH}
        className="grow"
        type="text"
        sizing="xs"
      />
      {formState.isDirty && (
        <Button className="absolute right-2 top-[5px] rounded p-0" size="xs" type="submit" color="green">
          <CheckIcon className="h-4 w-4" />
        </Button>
      )}
    </form>
  )
}

export default Name
