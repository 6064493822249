import { useState } from 'react'

const adjectives = [
  'funny',
  'sleepy',
  'happy',
  'sad',
  'angry',
  'silly',
  'crazy',
  'lazy',
  'brave',
  'bright',
  'calm',
  'energetic',
  'fierce',
  'gentle',
  'jolly',
  'kind',
  'lively',
  'nice',
  'proud',
  'witty',
]

const nouns = [
  'cobra',
  'bear',
  'lion',
  'tiger',
  'wolf',
  'fox',
  'panda',
  'eagle',
  'shark',
  'whale',
  'dragon',
  'unicorn',
  'phoenix',
  'griffin',
  'pegasus',
  'mermaid',
  'sphinx',
  'jellyfish',
  'hydra',
  'kraken',
]

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max)
}

export function generateFunnyName() {
  const adjective = adjectives[getRandomInt(adjectives.length)]
  const noun = nouns[getRandomInt(nouns.length)]
  return `${adjective[0].toUpperCase() + adjective.substring(1)} ${noun}`
}

export const useFunnyNameGenerator = () => {
  const [name, setName] = useState(generateFunnyName())
  const key = name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase()
  return {
    name,
    key,
    regenerate: () => setName(generateFunnyName()),
  }
}
