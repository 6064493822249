export interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  return (
    <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-wall-secondary-bg-light">
      <img src="/logo.svg" />
    </div>
  )
}

export default Logo
