import { useCallback, useEffect, useState } from 'react'

const WIDE_SCREEN_WIDTH = 650
export const useIsWideScreen = () => {
  const isPhone =
    navigator.userAgent.includes('Mobile') ||
    navigator.userAgent.includes('Android') ||
    navigator.userAgent.includes('iPhone')

  const [isWideScreen, setIsWideScreen] = useState(!isPhone && window.innerWidth > WIDE_SCREEN_WIDTH)

  const resizeHandler = useCallback(() => {
    setIsWideScreen(!isPhone && (window?.innerWidth || 0) > WIDE_SCREEN_WIDTH)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return isWideScreen
}
