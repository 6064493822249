import { useEffect, useMemo, useState } from 'react'
import { PhotoIcon, DocumentIcon } from '@heroicons/react/24/outline'

interface FilePreviewProps {
  ext?: string | null
  src?: string
  file?: File
}

const IMAGES_EXT = ['webp', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'png', 'apng']

function checkImage(imageSrc: string = '', good: () => void, bad: () => void) {
  const img = new Image()
  img.onload = good
  img.onerror = bad
  img.src = imageSrc
}

const FilePreview = ({ ext, src, file }: FilePreviewProps) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [isPreview, setIsPreview] = useState(false)
  const isImage = useMemo(() => (ext ? IMAGES_EXT.some((imgExt) => imgExt === ext) : false), [ext])

  useEffect(() => {
    if (isImage) {
      if (file) {
        const fakeSrc = URL.createObjectURL(file)
        setImgSrc(fakeSrc)
        setIsPreview(true)
      } else {
        checkImage(
          imgSrc,
          () => {
            setIsPreview(true)
          },
          () => {
            setIsPreview(false)
          },
        )
      }
    }
  }, [isImage])
  if (isImage) {
    return isPreview ? <img src={imgSrc} className="max-h-8 max-w-8" /> : <PhotoIcon className="h-5 w-5" />
  }

  return <DocumentIcon className="h-5 w-5" />
}

export default FilePreview
