import { Buffer } from 'buffer'

export const decodeToken = (token: string = ''): { exp: number; user_id: string } => {
  try {
    const dataPart = token.split('.')[1]
    return JSON.parse(Buffer.from(dataPart, 'base64').toString())
  } catch (err) {
    return {
      exp: 0,
      user_id: '',
    }
  }
}

export const checkTokenIsExpired = (token: string = '') => {
  if (!token) {
    return false
  }
  const decoded = decodeToken(token)

  if (!decoded.exp) {
    return false
  }

  if (decoded.exp < Date.now() / 1000) {
    return true
  }
  return false
}
