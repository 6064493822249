import { useEffect, useState } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useUtils } from '@telegram-apps/sdk-react'
import { useTranslation } from 'react-i18next'

import { Button } from 'flowbite-react'

export const ErrorBoundary: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'errorBoundary' })
  const navigate = useNavigate()
  const error = useRouteError()
  const [errorUUID, setErrorUUID] = useState('')

  useEffect(() => {
    const uuid = Sentry.captureException(error)
    setErrorUUID(uuid)
  }, [error])

  const utils = useUtils()
  return (
    <div className="m-3 mt-28 flex flex-col items-center justify-center gap-3 rounded border border-wall-gray-light p-3 text-wall-gray-neutral dark:border-wall-gray-dark">
      <img src="/logo.svg" className="h-24 w-24" />
      <p>
        {t('title')}{' '}
        <span
          className="cursor-pointer text-wall-main-light underline dark:text-wall-main-dark"
          onClick={() => utils.openTelegramLink('https://t.me/the_wall_supportbot')}
        >
          {t('supportLabel')}
        </span>
      </p>
      <p className="whitespace-normal">
        {t('sendIdLabel')}{' '}
        <span className="font-medium text-wall-main-light dark:text-wall-main-dark">{errorUUID}</span>
      </p>
      <div className="flex w-full justify-end">
        <Button onClick={() => navigate('/')} color="gray" size="xs">
          {t('getBackLabel')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundary
