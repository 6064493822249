export const useIsIos = () => {
  return navigator.userAgent.includes('iPhone')
}

export const useIsAndroid = () => {
  return navigator.userAgent.includes('Android')
}

export const useIsIpad = () => {
  return navigator.userAgent.includes('Mac OS') && navigator.maxTouchPoints > 2
}
export const useIsMobileDevice = () => {
  const isIpad = useIsIpad()
  return (
    navigator.userAgent.includes('Mobile') ||
    navigator.userAgent.includes('Android') ||
    navigator.userAgent.includes('iPhone') ||
    isIpad
  )
}
