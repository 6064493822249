import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import cx from 'clsx'

import Group from './components/Group'
import Card from './components/Card'

import { useGetProject, useUpdateProject } from '@/projects/queries'
import useDnDHelpers from '@/utils/hooks/useDnDHelpers'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'

import { IStatusResponse } from '@/projects/models/IProject'
import { toast } from 'react-toastify'

const Statuses = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.statuses' })
  const isWide = useIsWideScreen()

  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const { data: project } = useGetProject(projectId, teamspaceId)

  const { mutateAsync: patchProject } = useUpdateProject(projectId, teamspaceId)

  const statuses = useMemo(() => {
    return [{ id: 'statuses', cards: project.settings.statuses.map((status) => ({ ...status, id: status.uuid })) }]
  }, [project])

  const [columns, setColumns] = useState(statuses)

  useEffect(() => {
    setColumns(statuses)
  }, [project])

  useEffect(() => {
    sendEvent('open-project-statuses-settings')
  }, [])

  const { sensors, activeElement, handleDragEnd, handleDragOver, handleDragStart } = useDnDHelpers<
    IStatusResponse & { id: string }
  >({
    columns,
    setColumns,
  })

  const handleDragEndAndUpdate = async (event: DragEndEvent) => {
    const newColumns = await handleDragEnd(event)
    if ((newColumns?.[0]?.cards?.length || 0) < project.settings.statuses.length) {
      console.error('Columns before move', project.settings.statuses)
      console.error('Columns after move', newColumns?.[0]?.cards || [])
      toast.error(t('moveError'))
      return
    }
    await patchProject({
      settings: {
        ...project.settings,
        statuses:
          newColumns?.[0]?.cards?.map((status, index) => ({
            category: status.category,
            name: status.name,
            position: index,
            uuid: status.id,
          })) || [],
      },
    })
  }

  const handleAddStatus = (card: IStatusResponse & { id: string }) => {
    setColumns((columns) => {
      return [
        {
          id: 'statuses',
          cards: [...columns[0].cards, card],
        },
      ]
    })
  }

  return (
    <div
      className={cx('flex flex-col gap-2 px-3', {
        'max-w-2xl': isWide,
      })}
    >
      <p className="text-sm">{t('title')}</p>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEndAndUpdate}
        onDragOver={handleDragOver}
      >
        <div className="grid min-h-[200px] touch-none gap-3 overflow-x-auto pb-3">
          <Group id="statuses" cards={columns[0].cards} onAddStatus={handleAddStatus} ownerId={project.owner_id} />
        </div>
        {createPortal(
          <DragOverlay>
            {activeElement && <Card card={activeElement} className="select-none dark:text-neutral-200" isOverlay />}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </div>
  )
}

export default Statuses
