import Select, { Option } from '@/components/Select'
import { Language } from '@/i18n'
import { useTranslation } from 'react-i18next'

export interface LanguaueProps {
  code: Language
  onChange: (code: Language) => void
  onFocus?: () => void
  onBlur?: () => void
  className?: string
  disabled?: boolean
}

const options: Option<Language>[] = [
  {
    value: Language.EN,
    label: 'English',
  },
  {
    value: Language.UK,
    label: 'Українська',
  },
  {
    value: Language.PT,
    label: 'Português',
  },
  {
    value: Language.RU,
    label: 'Русский',
  },
]

export const Languaue: React.FC<LanguaueProps> = ({ code, disabled, className, onChange, onFocus, onBlur }) => {
  const { t } = useTranslation('common', { keyPrefix: 'settings' })
  return (
    <Select
      value={code}
      title={t('selectLanguageLabel')}
      options={options}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      buttonClassName={className}
    />
  )
}

export default Languaue
