import AddNewTicket from '@/components/AddNewTicket'
import React from 'react'

export interface BacklogProps {}

export const Backlog: React.FC<BacklogProps> = () => {
  return (
    <div>
      <span>project Backlog</span>
      <AddNewTicket />
    </div>
  )
}

export default Backlog
