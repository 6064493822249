import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { Option } from '@/components/Select'
import HighIcon from '@/assets/priority/high.svg?react'
import MediumIcon from '@/assets/priority/medium.svg?react'
import LowIcon from '@/assets/priority/low.svg?react'

import { Priority as PriorityEnum } from '@/projects/models/IProject'

export const usePriorityOptions = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.priority' })
  const priorities = useMemo(
    () => [
      {
        id: PriorityEnum.High,
        title: t('high'),
        Icon: HighIcon,
        bg: 'bg-wall-red-light dark:bg-wall-red-dark hover:bg-wall-red-light/90 dark:hover:bg-wall-red-dark/90',
        iconColor: 'text-wall-red-dark dark:text-wall-red-light',
      },
      {
        id: PriorityEnum.Medium,
        title: t('medium'),
        Icon: MediumIcon,
        bg: 'bg-wall-yellow-light dark:bg-wall-yellow-dark hover:bg-wall-yellow-light/90 dark:hover:bg-wall-yellow-dark/90',
        iconColor: 'text-wall-yellow-dark dark:text-wall-yellow-light',
      },
      {
        id: PriorityEnum.Low,
        title: t('low'),
        Icon: LowIcon,
        bg: 'bg-wall-green-light dark:bg-wall-green-dark hover:bg-wall-green-light/90 dark:hover:bg-wall-green-dark/90',
        iconColor: 'text-wall-green-dark dark:text-wall-green-light',
      },
    ],
    [t],
  )

  const options = useMemo(() => {
    return priorities.map<Option<PriorityEnum>>(({ id, bg, title, Icon, iconColor }) => ({
      value: id,
      label: title,
      element: (
        <div className="flex w-full items-center gap-2">
          <div className={cx('flex h-7 w-7 items-center justify-center rounded', bg)}>
            <Icon className={iconColor} />
          </div>
          <span className="text-sm">{title}</span>
        </div>
      ),
    }))
  }, [priorities])

  return { options, priorities }
}
