import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import cx from 'clsx'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'

import Priority from '@/components/Priority'
import UserSelect from '@/projects/components/UserSelect'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import routes from '@/utils/routes'
import { useGetProject, usePatchTicket } from '@/projects/queries'
import { columns as columnsAtom } from '@/projects/store'

import { ICard, StatusCategory } from '@/projects/models/IProject'
import { useMemo } from 'react'

export interface CardProps {
  card: ICard
  isWide: boolean
  columnStatus?: string
  className?: string
  isOverlay?: boolean
}

const Card: React.FC<CardProps> = ({ card, className, isOverlay, isWide }) => {
  const navigate = useNavigate()
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { attributes, listeners, setNodeRef, isDragging, isSorting, isOver, active } = useSortable({
    id: card.id,
  })

  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: updateTicket } = usePatchTicket(teamspaceId, projectId, card.id)
  const [, setColumns] = useAtom(columnsAtom)

  const { isOutdated } = useMemo(() => {
    const statusCategory = project.settings.statuses.find((s) => s.uuid === card.status)?.category
    const isOutdated = statusCategory !== StatusCategory.Done && dayjs(card.due_date).isBefore(new Date(), 'day')
    return {
      isOutdated,
    }
  }, [project])

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={cx(
        'block w-full touch-pan-y select-none rounded border bg-wall-secondary-bg-light px-3 py-2 text-start shadow dark:bg-wall-secondary-bg-dark',
        className,
        {
          'border-transparent': !isDragging,
          'border-dashed opacity-50': isDragging,
          'touch-none': isSorting || isDragging || isOver || !!active,
          hidden: !isWide && !!active && !isDragging,
        },
      )}
      onClick={() => {
        navigate(generatePath(routes.private.ticket, { projectId, teamspaceId, ticketId: card.id }))
      }}
    >
      <div id={card.id} className="flex flex-col gap-4">
        <div className="flex items-start justify-between gap-2">
          <div>
            <p className={cx('text-xs font-bold text-wall-main-light dark:text-wall-main-dark')}>{card?.number}</p>
            <p className={cx('text-sm')} data-amp-mask>
              {card?.title}
            </p>
          </div>
          <div className="flex gap-2">
            <UserSelect
              disabled={!!isDragging || !!isOver || !!isSorting || isOverlay}
              onSelect={(assignee) => {
                updateTicket({ assignee })
                setColumns((prev) => {
                  const newColumns = [...prev]
                  const index = newColumns.findIndex((column) => column.id === card.status)
                  newColumns[index].cards = newColumns[index].cards.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        assignee,
                      }
                    }
                    return c
                  })
                  return newColumns
                })
              }}
              value={card.assignee}
              ticketId={card.id}
            />
            <Priority
              priority={card?.priority}
              disabled={!!isDragging || !!isOver || !!isSorting || isOverlay}
              onChange={(priority) => {
                updateTicket({ priority })
                setColumns((prev) => {
                  const newColumns = [...prev]
                  const index = newColumns.findIndex((column) => column.id === card.status)
                  newColumns[index].cards = newColumns[index].cards.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        priority,
                      }
                    }
                    return c
                  })
                  return newColumns
                })
              }}
            />
          </div>
        </div>
        <div className="flex min-h-5 items-end justify-end gap-2">
          {card.due_date && (
            <div
              className={cx('flex items-center gap-1 text-sm', {
                'text-red-500': isOutdated,
              })}
            >
              {isOutdated ? <ExclamationCircleIcon className="h-4 w-4" /> : <CalendarDaysIcon className="h-4 w-4" />}
              {dayjs(card.due_date).format('MMM DD')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
