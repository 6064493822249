import { NavLink, NavLinkProps } from 'react-router-dom'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

export interface NavItemProps extends NavLinkProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const NavItem: React.FC<NavItemProps> = ({ to, Icon }) => {
  return (
    <NavLink to={to} className="group inline-flex flex-col items-center justify-start p-3">
      {({ isActive }) => {
        return (
          <>
            <Icon
              className={twMerge(
                cx(
                  'h-5 w-5 text-gray-500 group-hover:text-wall-secondary dark:text-gray-400 dark:group-hover:text-wall-secondary',
                  {
                    'text-wall-secondary dark:text-wall-secondary': isActive,
                  },
                ),
              )}
              aria-hidden="true"
            />
          </>
        )
      }}
    </NavLink>
  )
}

export default NavItem
