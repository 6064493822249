import React from 'react'
import { useParams } from 'react-router-dom'

import Post from '../Post'
import { SkeletonComponent as Skeleton } from '@/components/Skeleton'

import { useGetProjectDashboardArticles } from '@/home/queries'
import { withLoader } from '@/utils/hocs/withLoader'

export interface PostsProps {}

const PostsContent: React.FC<PostsProps> = () => {
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const { data: dashboard } = useGetProjectDashboardArticles(teamspaceId, projectId)

  return (
    <div className="flex w-full gap-2 overflow-auto">
      {dashboard?.articles
        ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        ?.map((post) => <Post img={post.file} postLink={post.url} title={post.name} key={post.name} id={post.id} />)}
    </div>
  )
}

export const Posts = withLoader(
  PostsContent,
  <div className="flex w-full gap-2 overflow-auto">
    <Skeleton className="h-24 w-20 bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
    <Skeleton className="h-24 w-20 bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
    <Skeleton className="h-24 w-20 bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
  </div>,
)
export default Posts
