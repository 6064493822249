import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from '@/components/Select'
import { StatusCategory } from '@/projects/models/IProject'

export const useStatusCategoryOptions: () => Option<StatusCategory>[] = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.statuses.categories' })
  return useMemo(
    () => [
      {
        label: t('todo'),
        value: StatusCategory.Todo,
        color: '#9D61F1',
      },
      {
        label: t('in_progress'),
        value: StatusCategory.InProgress,
        color: '#FF805C',
      },
      {
        label: t('done'),
        value: StatusCategory.Done,
        color: '#52BE65',
      },
    ],
    [t],
  )
}
