import {
  createBrowserRouter,
  generatePath,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Flowbite, useThemeMode } from 'flowbite-react'
import { ToastContainer } from 'react-toastify'
import { useViewport, useSwipeBehavior, useMiniApp, useLaunchParams, useThemeParams } from '@telegram-apps/sdk-react'
import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { bindThemeParamsCSSVars, bindViewportCSSVars, bindMiniAppCSSVars } from '@telegram-apps/sdk'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

import Layout from './components/Layout'
import Home from './home/pages/Home'
import Project from './projects/pages/Project'
import Board from './projects/pages/Board'
import ProjectSettings from './projects/pages/Settings'
import ProjectStatuses from './projects/pages/Statuses'
import ProjectUsers from './projects/pages/Users'
import Backlog from './projects/pages/Backlog'
import TicketLayout from './projects/pages/Ticket/components/Layout'
import Ticket from './projects/pages/Ticket/components/TicketContent'
import Comments from './projects/pages/Ticket/components/Comments'
import RecurringTickets from './projects/pages/Recurring'
import NewTicket from './projects/pages/NewTicket'
import Welcome from './teamspace/pages/Welcome'
import Settings from './settings/pages/Settings'
import ErrorBoundary from './components/ErrorBoundary'

import './i18n'
import routes from './utils/routes'
import { useAuth } from './auth/queries'
import { useGetAllProjects } from './projects/queries'
import useAmplitude, { sendEvent } from './utils/hooks/useAmplitude'
import { useIsIpad } from './utils/hooks/useIsMobileDevice'

import { project } from './projects/store'

import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'

import theme from './flowbiteTheme'
import './index.css'

const getStartPathIsValid = (path: string) => {
  if (!path.includes('teamspace_id') && !path.includes('project_id')) {
    return true
  }

  return false
}

const NavigationLayout = () => {
  const { teamspaceId: teamspaceIdParams, projectId: projectIdParams } = useParams<{
    teamspaceId: string
    projectId: string
  }>()
  const navigate = useNavigate()
  const { data: projects } = useGetAllProjects()
  const launchParams = useLaunchParams()

  const [projectIdStore, setProjectIdStore] = useAtom(project)

  const projectId = projectIdParams || projectIdStore || projects?.[0]?.uuid
  const teamspaceId = projects.find((project) => project.uuid === projectId)?.teamspace_id
  useEffect(() => {
    if (launchParams.startParam) {
      try {
        const path = atob(launchParams.startParam)
        if (getStartPathIsValid(path)) {
          navigate(path)
        }
        launchParams.startParam = ''
      } catch (e) {
        console.error(e)
      }
    }
  }, [launchParams.startParam])

  useEffect(() => {
    if (projectId) {
      setProjectIdStore(projectId)
    }
  }, [projectId])

  if (!projects.length) {
    return <Navigate to="/" />
  }

  if (!teamspaceIdParams) {
    return <Navigate to={generatePath(routes.private.home, { teamspaceId, projectId })} />
  }

  return <Outlet />
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  { path: '/', element: <Welcome />, errorElement: <ErrorBoundary /> },
  {
    element: <NavigationLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <TicketLayout />,
        children: [
          {
            path: routes.private.ticket,
            element: <Ticket />,
          },
          {
            path: routes.private.ticketComments,
            element: <Comments />,
          },
        ],
      },
      {
        path: routes.private.newTicket,
        element: <NewTicket />,
      },
      {
        element: <Layout />,
        children: [
          {
            path: routes.private.home,
            element: <Home />,
          },
          {
            path: routes.private.settings,
            element: <Settings />,
          },

          {
            path: routes.private.projects,
            element: <Project />,
          },
          {
            path: routes.private.projectById,
            element: <Project />,
            children: [
              { path: routes.private.projectById, element: <Board /> },
              {
                path: routes.private.board,
                element: <Board />,
              },
              {
                path: routes.private.projectBacklog,
                element: <Backlog />,
              },
              {
                path: routes.private.projectSettings,
                element: <ProjectSettings />,
              },
              {
                path: routes.private.projectColumns,
                element: <ProjectStatuses />,
              },
              {
                path: routes.private.projectUsers,
                element: <ProjectUsers />,
              },
              {
                path: routes.private.recurring,
                element: <RecurringTickets />,
              },
            ],
          },
        ],
      },
    ],
  },
])

function App() {
  const { i18n } = useTranslation()
  const { mode, setMode } = useThemeMode()
  const vp = useViewport()
  const swipe = useSwipeBehavior()
  const miniApp = useMiniApp()
  const launchParams = useLaunchParams()
  const themeParams = useThemeParams()
  const isIpad = useIsIpad()

  const { data: authData } = useAuth(launchParams.initDataRaw || '')
  useAmplitude()

  useEffect(() => {
    if (authData?.user.language_code) {
      i18n.changeLanguage(authData.user.language_code)
    }
  }, [authData.user])

  useEffect(() => {
    try {
      miniApp.setHeaderColor(mode === 'light' ? '#ffffff' : '#1f2937')
      miniApp.setBgColor(mode === 'light' ? '#EBEAE9' : '#1f2937')
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [miniApp, mode])

  useEffect(() => {
    try {
      if (vp) {
        bindViewportCSSVars(vp)
        vp.expand()
        vp.sync()

        vp?.listen()
        const setHeight = (height: number = vp.height) => {
          const vh = (height + (isIpad ? 12 : 0)) * 0.01
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }
        setHeight()
        return vp.on('change:height', (h) => {
          setHeight(h)
          vp.sync()
        })
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [vp?.height])

  useEffect(() => {
    try {
      if (miniApp.isDark) {
        setMode('dark')
      } else {
        setMode('light')
      }
      return bindMiniAppCSSVars(miniApp, themeParams)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [miniApp, themeParams])

  useEffect(() => {
    try {
      return bindThemeParamsCSSVars(themeParams)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [themeParams])

  useEffect(() => {
    try {
      if (swipe) {
        swipe.disableVerticalSwipe?.()
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [swipe])

  useEffect(() => {
    document.getElementById('loader')?.classList?.add('hidden')
    sendEvent('launch-app')
    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        document.getElementById('root')?.classList.add('touch-none')
      }
    }

    const handleFocusOut = (event: FocusEvent) => {
      const target = event.target as HTMLElement
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        document.getElementById('root')?.classList.remove('touch-none')
      }
    }
    document.addEventListener('focusin', handleFocusIn)
    document.addEventListener('focusout', handleFocusOut)

    return () => {
      document.removeEventListener('focusin', handleFocusIn)
      document.removeEventListener('focusout', handleFocusOut)
    }
  }, [])

  return (
    <Flowbite theme={{ mode: miniApp.isDark ? 'dark' : 'light', theme }}>
      <div className="h-dinamic select-none bg-wall-main-bg-light text-neutral-700 transition-[height] dark:bg-wall-main-bg-dark dark:text-neutral-200">
        <RouterProvider router={router} />
        <ToastContainer theme={mode} className="text-sm" toastClassName="font-sans" />
      </div>
    </Flowbite>
  )
}

export default App
