import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ScrollRestoration } from 'react-router-dom'
import { Dispatch, SetStateAction } from 'react'

import Card from '../Card'
import CreateCard from '../CreateCard'

import { useDroppable } from '@dnd-kit/core'

import { ICard } from '@/projects/models/IProject'
import { useScrollRestorationRef } from '@/utils/hooks/useScrollRestorationRef'

export type ColumnType = {
  id: string
  title: string
  boardKey: string
  color?: string
  cards: ICard[]
  isWide: boolean
  isCreating: string | null
  showSwipeTip?: boolean
  setIsCreating: Dispatch<SetStateAction<string | null>>
}

const Column: React.FC<ColumnType> = ({
  id,
  title,
  cards,
  showSwipeTip = false,
  color,
  isCreating,
  isWide,
  setIsCreating,
}) => {
  const { setNodeRef, active } = useDroppable({ id })
  const scrollRestorationRef = useScrollRestorationRef(`#column-${id}`)

  const setIsCreatingColumn: (columnId: string) => Dispatch<SetStateAction<boolean>> = (columnId) => (isCreating) => {
    setIsCreating(isCreating ? columnId : null)
  }

  return (
    <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
      <ScrollRestoration />
      <div
        ref={setNodeRef}
        className={twMerge(
          cx('group relative h-full rounded', active ? 'h-auto' : 'h-full', {
            'animate-pulse': active?.data?.current?.sortable?.containerId === id,
            'min-w-[320px] max-w-[320px]': isWide,
            'min-w-[calc(100vw-24px)]': !isWide,
          }),
        )}
      >
        <div className="absolute z-10 w-full rounded bg-wall-main-bg-light dark:bg-wall-main-bg-dark">
          <div
            className="mb-3 flex w-full justify-between rounded px-3 py-2 text-white"
            style={{ backgroundImage: `linear-gradient(to right, ${color}, #33AED4)` }}
          >
            {title}:{' '}
            <div className="flex h-7 w-7 items-center justify-center rounded bg-cyan-700 px-2 font-medium">
              {cards.filter(Boolean).length}
            </div>
          </div>
          {!isWide && !active && (
            <CreateCard
              status={id}
              isCreating={id === isCreating}
              setIsCreating={setIsCreatingColumn(id)}
              isWide={isWide}
            />
          )}
        </div>
        <div
          ref={scrollRestorationRef}
          id={`column-${id}`}
          className={twMerge(
            cx(
              'disable-lens relative -mx-[2px] flex select-none flex-col overflow-auto px-[2px] pb-3',
              {
                'overflow-hidden pt-[172px]': isCreating && !isWide,
                'pt-[106px]': !isCreating && !isWide,
                'pt-[60px]': isWide,
              },
              active && !isWide ? 'h-auto gap-0 pt-14' : 'h-full gap-3',
            ),
          )}
        >
          {cards.filter(Boolean).map((card) => (
            <Card key={card.id} card={card} columnStatus={id} isWide={isWide} />
          ))}
          {isWide && (
            <CreateCard
              status={id}
              isCreating={id === isCreating}
              setIsCreating={setIsCreatingColumn(id)}
              isWide={isWide}
            />
          )}
          {!active && !isWide && <div className="min-h-8" />}
        </div>
        {showSwipeTip && !active && !isWide && !isCreating && (
          <div
            className="absolute left-1/2 top-1/2 h-[136px] w-[136px] -translate-x-1/2 -translate-y-1/2 bg-gray-500 bg-opacity-50"
            style={{ mask: 'url(/swipe.webp) no-repeat center', maskSize: 'contain' }}
            onMouseDown={(e) => e.preventDefault()}
            onTouchStart={(e) => e.preventDefault()}
          />
        )}
      </div>
    </SortableContext>
  )
}

export default Column
