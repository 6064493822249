import { useCallback, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useGetMe } from '@/auth/queries'
import * as Sentry from '@sentry/react'

const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY

const amplitudeInstance = amplitude.createInstance()

export const sendEvent = (eventName: string, data: Record<string, unknown> = {}) => {
  if (AMPLITUDE_API_KEY) {
    amplitudeInstance.track(eventName, { ...data })
  }
}

export const useAmplitude = () => {
  const { data: user } = useGetMe()

  const handleClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement
    const button = target?.closest('[data-amplitude]')
    const buttonEvent = button?.getAttribute('data-amplitude')

    if (buttonEvent) {
      sendEvent(`${buttonEvent}`, { button: buttonEvent })
    }
  }, [])

  useEffect(() => {
    if (!AMPLITUDE_API_KEY) {
      return
    }

    if (user && !amplitudeInstance.getDeviceId()) {
      Sentry.setUser({ id: user.uuid })
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1,
        privacyConfig: {
          maskSelector: ['[data-amp-mask]'],
        },
      })

      amplitudeInstance.init(AMPLITUDE_API_KEY, user.uuid, {
        serverZone: 'EU',
        autocapture: {
          sessions: true,
          pageViews: false,
          attribution: false,
          elementInteractions: false,
          fileDownloads: false,
          formInteractions: false,
        },
      })

      amplitudeInstance.add(sessionReplayTracking)
      const identifyEvent = new amplitude.Identify()
      amplitudeInstance.identify(identifyEvent)
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [user])
}
export default useAmplitude
