import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Select from '@/components/Select'

export interface DaysOfMonthSelectorProps {
  value?: (number | string)[]
  title: string
  label: string
  onChange: (value?: (number | string)[]) => void
}

export const DaysOfMonthSelector: React.FC<DaysOfMonthSelectorProps> = ({ value, title, label, onChange }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.recurring' })
  const DAYS_OF_MONTH_OPTIONS = useMemo(() => {
    const options: { value: number | string; label: string }[] = Array.from({ length: 31 }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    options.push({
      value: 'last',
      label: t('lastDayOfMonthLabel'),
    })
    return options
  }, [t])

  const selectedPlaceholder = useMemo(() => {
    return value?.length ? t('selectedDaysPlaceholder') : ''
  }, [value])

  return (
    <Select
      options={DAYS_OF_MONTH_OPTIONS}
      isMulti
      title={title}
      label={label}
      value={value}
      onChange={onChange}
      isMultipleSelectHightlight={false}
      placeholder={selectedPlaceholder}
    />
  )
}

export default React.memo(DaysOfMonthSelector)
