import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import cx from 'clsx'

import Navigation from './components/Navigation'
import Skeleton from '../Skeleton'

export interface LayoutProps {}
const LayoutWrapper = () => {
  return (
    <main className="h-full max-w-full">
      <section className={cx('flex h-[calc(100%-48px)] w-full flex-col gap-3 overflow-auto')}>
        <Suspense fallback={<Skeleton isActive className="h-full w-full" />}>
          <Outlet />
        </Suspense>
      </section>
      <Navigation />
    </main>
  )
}

export default LayoutWrapper
