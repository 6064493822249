import { Button, Drawer } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'

export interface ConfirmationDialogProps {
  confirmationTitle?: string
  confirmationText?: string | JSX.Element
  confirmLabel?: string
  cancelLabel?: string
  isOpen?: boolean
  isLoading?: boolean
  onConfirm: () => void
  onClose: () => void
  withoutCancelBtn?: boolean
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  confirmationTitle,
  confirmationText,
  confirmLabel,
  isOpen,
  isLoading = false,
  onConfirm,
  onClose,
  withoutCancelBtn = false,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'confirmationPopup' })
  const isIos = useIsIos()
  return (
    <Drawer open={isOpen} position="bottom" onClose={onClose} className={cx('rounded-t-md', { 'pb-10': isIos })}>
      {confirmationTitle && <Drawer.Header title={confirmationTitle} titleIcon={() => null} className="pr-6" />}
      <Drawer.Items>
        {confirmationText && <p className="mb-3 text-sm font-normal">{confirmationText}</p>}
        <div className="flex justify-end gap-2">
          {!withoutCancelBtn && (
            <Button size="xs" color="red" onClick={onClose} type="button">
              {t('cancelLabel')}
            </Button>
          )}
          <Button size="xs" color="green" onClick={onConfirm} disabled={isLoading} type="button">
            {confirmLabel ?? t('confirmLabel')}
          </Button>
        </div>
      </Drawer.Items>
    </Drawer>
  )
}

export default ConfirmationDialog
