import React from 'react'
import { useTranslation } from 'react-i18next'

const TitleInput = React.forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>((props, ref) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })

  return (
    <input
      className="w-full bg-transparent text-xl focus:outline-none"
      autoFocus
      placeholder={t('titlePlaceholder')}
      {...props}
      ref={ref}
    />
  )
})

export default TitleInput
