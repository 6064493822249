export const routes = {
  public: {},
  private: {
    home: '/home/teamspace/:teamspaceId/project/:projectId',
    profile: '/teamspace/:teamspaceId/profile',
    projects: '/teamspace/:teamspaceId/project',
    settings: '/settings/teamspace/:teamspaceId',
    projectById: '/teamspace/:teamspaceId/project/:projectId',
    board: '/teamspace/:teamspaceId/project/:projectId/board',
    projectSettings: '/teamspace/:teamspaceId/project/:projectId/settings',
    projectColumns: '/teamspace/:teamspaceId/project/:projectId/columns',
    projectBacklog: '/teamspace/:teamspaceId/project/:projectId/backlog',
    projectUsers: '/teamspace/:teamspaceId/project/:projectId/users',
    recurring: '/teamspace/:teamspaceId/project/:projectId/recurring',
    ticket: '/teamspace/:teamspaceId/project/:projectId/ticket/:ticketId/details',
    ticketComments: '/teamspace/:teamspaceId/project/:projectId/ticket/:ticketId/comments',
    newTicket: '/teamspace/:teamspaceId/new-ticket',
  },
}

export default routes
