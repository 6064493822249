import { Button, TextInput } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useGetProject, useUpdateProject } from '@/projects/queries'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useGetMe } from '@/auth/queries'

export interface KeyProps {}

type Inputs = {
  key: string
}

export const Key: React.FC<KeyProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.key' })
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const { data: me } = useGetMe()
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: updateProject } = useUpdateProject(projectId, teamspaceId)

  const { register, handleSubmit, formState, reset } = useForm<Inputs>({
    values: {
      key: project.key,
    },
  })
  const onSubmit = async (data: Inputs) => {
    await updateProject({ key: data.key })
    reset({ key: data.key })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative flex items-center gap-2" id="invite-user">
      <p className="w-36 text-sm">{t('title')}</p>
      <TextInput
        {...register('key')}
        maxLength={3}
        disabled={me.uuid !== project?.owner_id}
        className="grow"
        type="text"
        sizing="xs"
      />
      {formState.isDirty && (
        <Button
          form="invite-user"
          className="absolute right-2 top-[5px] rounded p-0"
          size="xs"
          type="submit"
          color="green"
        >
          <CheckIcon className="h-4 w-4" />
        </Button>
      )}
    </form>
  )
}

export default Key
