import React from 'react'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'

const DEFAULT_ID = 'taskDescription'
const DescriptionInput = React.forwardRef<HTMLTextAreaElement, React.HTMLProps<HTMLTextAreaElement>>((props, ref) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.description' })

  return (
    <div>
      <label htmlFor={props.id ?? DEFAULT_ID} className="text-xs">
        {t('label')}
      </label>
      <textarea
        ref={ref}
        {...props}
        id={props.id ?? DEFAULT_ID}
        placeholder={t('placeholder')}
        className={cx(
          'tmax-h-[300px] min-h-36 w-full overflow-y-hidden rounded border-none border-transparent bg-transparent py-2 pb-10 ring-2 ring-transparent focus:border-transparent focus:ring-0 dark:bg-wall-secondary-bg-dark',
          {},
        )}
      />
    </div>
  )
})

export default DescriptionInput
