import { useCallback, useState } from 'react'

export const useToggleOpen = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [])

  return { isOpen, setIsOpen, toggleOpen }
}
