import { useCallback, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { DndContext, DragEndEvent, DragOverlay, Modifier } from '@dnd-kit/core'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { Spinner } from 'flowbite-react'

import Card from './components/Card'
import Columns from './components/Columns'
import Filter from '@/projects/components/Filter'
import AddNewTicket from '@/components/AddNewTicket'

import { withLoader } from '@/utils/hocs/withLoader'
import { useGetProject, useGetTicketList, usePatchTicket } from '@/projects/queries'
import useDnDHelpers from '@/utils/hooks/useDnDHelpers'

import { useStatusCategoryOptions } from '../Statuses/constants'
import { ICard, IColumn } from '@/projects/models/IProject'

import { filter as filterAtom, columns as columnsAtom } from '@/projects/store'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'

const COLUMN_HEIGHT = 64
const CARD_HEIGHT = 100
const CREATE_CARD_HEIGHT = 40

const positionModifier: (column: number, positionInColumn: number, offsetTop: number) => Modifier =
  (column, positionInColumn, offsetTop) => (args) => {
    const { transform } = args
    return {
      ...transform,
      y: transform.y - column * COLUMN_HEIGHT + (positionInColumn || 0) * CARD_HEIGHT - offsetTop + CREATE_CARD_HEIGHT,
    }
  }

const BoardComponent = () => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const [filter] = useAtom(filterAtom)

  const isWide = useIsWideScreen()

  const {
    data: tickets,
    isFetching: isTicketsFetching,
    isLoading,
    isPending,
  } = useGetTicketList(projectId, teamspaceId, filter)
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: patchTicket } = usePatchTicket(teamspaceId, projectId)
  const statusCategoryOptions = useStatusCategoryOptions()

  const columnsInit = useMemo(() => {
    return project.settings.statuses.map<IColumn>((status) => {
      const categoryOption = statusCategoryOptions.find((option) => option.value === status.category)
      return {
        id: status.uuid,
        title: status.name,
        color: categoryOption?.color,
        cards:
          tickets
            ?.map((ticket) => ({ ...ticket, id: ticket.uuid, number: ticket.ticket_id }))
            ?.filter((ticket) => ticket.status === status.uuid) || [],
      }
    })
  }, [project, tickets])

  useEffect(() => {
    setColumns(columnsInit)
  }, [tickets, project])

  const [columns, setColumns] = useAtom(columnsAtom)

  const { sensors, activeElement, handleDragEnd, handleDragOver, handleDragStart } = useDnDHelpers<ICard, IColumn>({
    columns,
    setColumns,
    dropTop: true,
  })

  const handleDragEndAndUpdate = useCallback(async (event: DragEndEvent) => {
    handleDragEnd(event)
    patchTicket({
      uuid: event.active.id as string,
      status: event.active.data.current?.sortable.containerId as string,
    })
  }, [])

  return (
    <>
      <Filter />
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEndAndUpdate}
        onDragOver={handleDragOver}
      >
        <Columns
          boardKey={project.key}
          columns={columns}
          isWide={isWide}
          id={projectId || ''}
          isLoading={isTicketsFetching || isLoading || isPending}
        />
        {isTicketsFetching && (
          <div className="flex h-full w-full justify-center">
            <Spinner className="h-6 w-6" />
          </div>
        )}
        {createPortal(
          <DragOverlay
            modifiers={
              isWide
                ? []
                : [
                    positionModifier(
                      activeElement?.columnNumber || 0,
                      activeElement?.positionInColumn || 0,
                      activeElement?.scrollTop || 0,
                    ),
                  ]
            }
          >
            {activeElement && (
              <Card
                card={activeElement}
                className="rotate-6 select-none dark:text-neutral-200"
                isOverlay
                isWide={isWide}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
        <AddNewTicket />
      </DndContext>
    </>
  )
}

export const Board = withLoader(
  BoardComponent,
  <div className="h-full w-full animate-pulse rounded bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />,
)

export default Board
