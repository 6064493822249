import cx from 'clsx'
import { useParams } from 'react-router-dom'

import Ticket from '@/home/components/Ticket'
import { useGetTicketList } from '@/projects/queries'
import { useTranslation } from 'react-i18next'

export interface RecurringProps {}

export const Recurring: React.FC<RecurringProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.recurring' })
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { data: tickets } = useGetTicketList(projectId, teamspaceId, {
    recurring: true,
  })
  return (
    <div
      className={cx('-mt-3 flex flex-col gap-2 overflow-auto p-3', {
        'max-w-2xl': false,
      })}
    >
      {tickets && !tickets?.length && (
        <p className="text-center text-sm text-wall-main-light dark:text-wall-main-dark">
          {t('noRecurringTasksLabel')}
        </p>
      )}
      {tickets?.map((ticket) => (
        <Ticket
          key={ticket.uuid}
          number={ticket.ticket_id}
          title={ticket.title}
          uuid={ticket.uuid}
          priority={ticket.priority}
          status={ticket.status}
        />
      ))}
    </div>
  )
}

export default Recurring
