import { useCallback } from 'react'

import { RecurringFrequency, RecurringSettings } from '../models/IProject'
import { RecurringValue } from '../components/Recurring'

export const useGetRecurringBody = () => {
  return useCallback((recurring: RecurringValue): RecurringSettings => {
    const daysOfWeek = recurring.daysOfWeek || [0]
    const daysOfMonth = recurring.daysOfMonth || [0]
    const hours = recurring.hour || 0
    const minutes = recurring.minute || 0

    return {
      frequency: recurring.frequency || RecurringFrequency.DAILY,
      days_of_week: recurring.frequency === RecurringFrequency.WEEKLY ? daysOfWeek : null,
      days_of_month: recurring.frequency === RecurringFrequency.MONTHLY ? daysOfMonth : null,
      hours,
      minutes,
    }
  }, [])
}
