import SwipeToDelete from 'react-swipe-to-delete-ios'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useDeleteFile } from '@/projects/queries'
import { useParams } from 'react-router-dom'

import './styles.css'
import clsx from 'clsx'
import FilePreview from './FilePreview'
import { useUtils } from '@telegram-apps/sdk-react'
import { useEffect, useRef, useState } from 'react'
import { getFilenameParts } from './utils'

interface FileItemProps {
  name: string
  src?: string
  id?: string
  justUploaded?: boolean
  isNewTicket?: boolean
  file?: File
  onDelete?: (name: string) => void
}
const FileItem = ({ name: fileName, src, id, justUploaded, isNewTicket, file, onDelete }: FileItemProps) => {
  const { ticketId, projectId, teamspaceId } = useParams<{ ticketId: string; projectId: string; teamspaceId: string }>()
  const { name, ext } = getFilenameParts(fileName)

  const { mutateAsync: deleteFile } = useDeleteFile(projectId, teamspaceId, ticketId)

  const utils = useUtils()

  const [uploading, setUploading] = useState(justUploaded)

  useEffect(() => {
    if (uploading && isNewTicket) {
      setTimeout(() => {
        setUploading(false)
      }, 3000)
    }
  }, [uploading, isNewTicket])

  const [isSwiping, setIsSwiping] = useState(false)
  const touchStartX = useRef<number | null>(null)

  const handleDelete = () => {
    if (id) {
      deleteFile(id)
      onDelete?.(fileName)
    }
  }

  const download = () => {
    if (src && !isSwiping) utils.openLink(src)
  }

  // Desktop swipe detection (mouse)
  const handleMouseDown = (e: React.MouseEvent) => {
    touchStartX.current = e.clientX
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (touchStartX.current !== null) {
      const mouseCurrentX = e.clientX
      const deltaX = Math.abs(touchStartX.current - mouseCurrentX)
      if (deltaX > 5) {
        setIsSwiping(true)
      }
    }
  }

  const handleMouseUp = () => {
    setTimeout(() => {
      setIsSwiping(false)
      touchStartX.current = null
    }, 300)
  }

  return (
    <SwipeToDelete
      id={`${'swiper'}-${id}`}
      onDelete={handleDelete}
      height={48}
      transitionDuration={250}
      deleteWidth={50}
      deleteComponent={<TrashIcon className={clsx('mx-auto h-6 w-6 text-wall-red-light', { hidden: uploading })} />}
      deleteColor="inherit"
      className={clsx('relative mb-1 rounded-md last:mb-0', { uploading })}
      disabled={uploading}
    >
      <div
        className={clsx('flex h-12 gap-2 rounded-md bg-wall-dirtygray-light p-2 text-xs hover:cursor-pointer', {
          'pointer-events-none animate-pulse hover:cursor-auto': uploading,
          'dark:bg-wall-main-bg-dark': isNewTicket,
          'dark:bg-wall-secondary-bg-dark': !isNewTicket,
        })}
        onClick={download}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div className="flex h-8 w-8 min-w-8 items-center justify-center">
          <FilePreview ext={ext} src={src} file={file} />
        </div>
        <div>
          <div className="line-clamp-1 font-semibold">{name}</div>
          <div className="text-[10px]">{ext}</div>
        </div>
      </div>
    </SwipeToDelete>
  )
}

export default FileItem
