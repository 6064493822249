import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'clsx'

import Select from '@/components/Select'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { useGetAllProjects, useCreateProject } from '@/projects/queries'
import { useGetTeamspaces, useCreateTeamspace } from '@/teamspace/queries'
import { project as projectAtom } from '@/projects/store'
import { useGetMe } from '@/auth/queries'
import { useFunnyNameGenerator } from '@/utils/hooks/useFunnyNameGenerator'
import { sendEvent } from '@/utils/hooks/useAmplitude'

import { IProjectListItemResponse, IProjectResponse } from '@/projects/models/IProject'

export interface TeamspaceSelectorProps {}

export const TeamspaceSelector: React.FC<TeamspaceSelectorProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'projectSelector' })
  const location = useLocation()
  const navigate = useNavigate()

  const { data: teamspaces } = useGetTeamspaces()
  const { data: projects } = useGetAllProjects()
  const { data: me } = useGetMe()
  const { mutateAsync: createProject } = useCreateProject()
  const { mutateAsync: createTeamspace } = useCreateTeamspace()

  const isTeamspaceCreatedOnlyOnce = useRef(false)

  const { name: projectName, key: projectKey, regenerate } = useFunnyNameGenerator()

  const [createProjectForTeamspace, setCreateProjectForTeamspace] = useState<string>()
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const { teamspaceId: teamspaceFromParams, projectId: projectIdFromParams } = useParams<{
    teamspaceId: string
    projectId: string
  }>()

  const [selectedProject, setSelectedProject] = useAtom(projectAtom)

  const project = projects?.find((project) => project.uuid === selectedProject) || projects?.[0]

  const getPathname = (project: IProjectListItemResponse | IProjectResponse) => {
    let newPathname = location.pathname
    if (teamspaceFromParams) {
      newPathname = newPathname.replace(teamspaceFromParams, project.teamspace_id)
    }
    if (projectIdFromParams) {
      newPathname = newPathname.replace(projectIdFromParams, project.uuid)
    }

    return newPathname
  }

  useEffect(() => {
    if (!!teamspaces && !teamspaces.some((ts) => ts.owner_id === me?.uuid) && !isTeamspaceCreatedOnlyOnce.current) {
      ;(async () => {
        isTeamspaceCreatedOnlyOnce.current = true
        await createTeamspace({
          name: [me?.first_name, me?.last_name].filter(Boolean).join(' '),
        })
      })()
    }
  }, [teamspaces])

  const options = useMemo(() => {
    const options = teamspaces
      ?.filter(
        (ts) => !!projects.filter((project) => project.teamspace_id === ts.uuid)?.length || ts.owner_id === me?.uuid,
      )
      ?.map((teamspace) => {
        const tsProjects = projects
          .filter((project) => project.teamspace_id === teamspace.uuid)
          .map((project) => {
            return (
              <Fragment key={project.uuid}>
                <Link
                  to={getPathname(project)}
                  onClick={() => {
                    setSelectedProject(project.uuid)
                    setIsSelectOpen(false)
                  }}
                  className={cx(
                    'flex w-full items-center gap-2 transition-colors hover:text-wall-main-light hover:dark:text-wall-main-dark',
                    {
                      'text-wall-main-light dark:text-wall-main-dark': project.uuid === selectedProject,
                    },
                  )}
                >
                  <div className="flex h-8 w-8 items-center justify-center rounded-md bg-wall-main-light text-center text-white dark:bg-wall-main-dark">
                    {project.key}
                  </div>
                  {project.name}
                </Link>
                <div className="h-px w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
              </Fragment>
            )
          })
        return {
          value: teamspace.uuid,
          label: teamspace.name,
          clickable: false,
          element: (
            <div className="mb-2 flex w-full flex-col gap-1 rounded p-2 dark:bg-wall-main-bg-dark">
              <div className="flex items-center gap-2 rounded text-xs">{teamspace?.name} - teamspace</div>
              <div className="flex flex-col items-start gap-1 pl-3 text-sm font-normal">
                {tsProjects}
                {teamspace.owner_id === me.uuid && (
                  <button
                    className="flex items-center gap-2 transition-colors hover:text-wall-main-light hover:dark:text-wall-main-dark"
                    onClick={() => {
                      setCreateProjectForTeamspace(teamspace.uuid)
                      setIsSelectOpen(false)
                    }}
                  >
                    <PlusCircleIcon className="h-8 w-8 text-wall-main-dark" /> {t('createLabel')}
                  </button>
                )}
              </div>
            </div>
          ),
        }
      })

    return options
  }, [teamspaces, location.pathname, projects, selectedProject])

  if (!options?.length) {
    return null
  }

  return (
    <div className="flex items-start justify-center px-3 py-1.5">
      <Select
        title={t('title')}
        open={isSelectOpen}
        onBlur={() => setIsSelectOpen(false)}
        onFocus={() => setIsSelectOpen(true)}
        Button={(props) => (
          <button
            className="z-10 h-8 w-8 rounded-md border border-wall-main-light bg-slate-100 text-center text-xs dark:border-wall-main-dark dark:bg-wall-main-bg-dark"
            {...props}
          >
            {project.key}
          </button>
        )}
        options={options}
      />
      <ConfirmationDialog
        confirmationTitle={t('confirmCreateTitle')}
        confirmationText={
          <Trans components={{ b: <strong /> }} t={t} i18nKey="confirmCreateMessage" values={{ name: projectName }} />
        }
        isOpen={!!createProjectForTeamspace}
        onClose={() => {
          setCreateProjectForTeamspace(undefined)
          regenerate()
        }}
        onConfirm={async () => {
          const project = await createProject({
            key: projectKey,
            name: projectName,
            teamspace_id: createProjectForTeamspace,
          })
          sendEvent('create-project')
          regenerate()
          setSelectedProject(project.uuid)
          navigate(getPathname(project))
          setCreateProjectForTeamspace(undefined)
        }}
      />
    </div>
  )
}

export default TeamspaceSelector
