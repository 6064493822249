import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import Toggle from '@/components/Toggle'

import { sendEvent } from '@/utils/hooks/useAmplitude'
import { useGetProjectUser, usePatchProjectUser } from '@/projects/queries'

export interface NotificationsProps {}

type Inputs = Record<string, boolean>

export const Notifications: React.FC<NotificationsProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.notifications' })
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { data: me } = useGetProjectUser(projectId, teamspaceId)
  const { mutateAsync: patchUser } = usePatchProjectUser(projectId, teamspaceId)

  const { watch, setValue } = useForm<Inputs>({
    defaultValues: { notificationsEnabled: me.dm_notifications },
  })

  return (
    <div className="grid gap-2">
      <Toggle
        label={t('personalNotifiocations')}
        text={t('personalNotifiocationsTip')}
        id="notificationsEnabled"
        checked={watch('notificationsEnabled')}
        color="green"
        onChange={async (value) => {
          setValue('notificationsEnabled', value)
          await patchUser({
            dm_notifications: value,
          })
          sendEvent(value ? 'enable-personal-project-notifications' : 'disable-personal-project-notifications')
        }}
      />
    </div>
  )
}

export default Notifications
