import { useUtils } from '@telegram-apps/sdk-react'

import Skeleton from '@/components/Skeleton'

import { sendEvent } from '@/utils/hooks/useAmplitude'

export interface PostProps {
  img: string
  id: number
  title: string
  postLink: string
}

const getIsImage = (url: string) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg']
  return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext))
}

const getIsVideo = (url: string) => {
  const videoExtensions = ['.mp4', '.webm', '.ogg', '.avi', '.mov']
  return videoExtensions.some((ext) => url.toLowerCase().endsWith(ext))
}

export const Post: React.FC<PostProps> = ({ img, title, postLink, id }) => {
  const utils = useUtils()

  const url = new URL(img)
  const isImage = getIsImage(url.pathname)
  const isVideo = getIsVideo(url.pathname)

  return (
    <button
      className="h-[100px] w-20 rounded"
      onClick={() => {
        if (postLink.includes('t.me')) {
          utils.openTelegramLink(postLink)
        } else {
          utils.openLink(postLink, { tryInstantView: true })
        }
        sendEvent('open-arcticle', { title, id })
      }}
    >
      <Skeleton isActive className="h-full w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark">
        {isImage && <img src={img} alt={title} className="rounded-lg" />}
        {isVideo && (
          <video autoPlay loop muted playsInline className="rounded-lg">
            <source src={img} type="video/mp4" />
          </video>
        )}
      </Skeleton>
    </button>
  )
}

export default Post
