import cx from 'clsx'
import { useHapticFeedback } from '@telegram-apps/sdk-react'
import { generatePath, Link, useLocation, useParams } from 'react-router-dom'

import { PlusIcon } from '@heroicons/react/24/outline'

import routes from '@/utils/routes'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'

interface AddNewTicketProps {}

const AddNewTicket: React.FC<AddNewTicketProps> = () => {
  const location = useLocation()
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const haptic = useHapticFeedback()

  const isIos = useIsIos()
  const isWide = useIsWideScreen()

  return (
    <Link
      to={generatePath(routes.private.newTicket, { teamspaceId })}
      state={{ projectId, from: location.pathname }}
      className={cx(
        'absolute rounded-xl bg-wall-main-light p-3 text-white hover:cursor-pointer dark:bg-wall-main-dark',
        isIos ? 'bottom-[90px]' : 'bottom-14',
        {
          'right-3': !isWide,
          'left-3': isWide,
        },
      )}
      onClick={() => {
        haptic.notificationOccurred('success')
      }}
    >
      <PlusIcon className="h-6 w-6" />
    </Link>
  )
}

export default AddNewTicket
