import { useCallback } from 'react'
import dayjs from 'dayjs'

import { ReminderFrequency, ReminderSettings } from '../models/IProject'
import { ReminderValue } from '../components/Reminder'

export const useGetReminderBody = () => {
  return useCallback((reminder: ReminderValue): ReminderSettings => {
    const day = reminder.day || [0]
    const hour = reminder.hour || 0
    const minute = reminder.minute || 0

    let date = dayjs(reminder.date as Date)

    date = date
      .minute(reminder.minute || 0)
      .hour(reminder.hour || 0)
      .second(0)

    return {
      frequency: reminder.frequency,
      datetime: [ReminderFrequency.ONCE].includes(reminder.frequency) ? date.format('YYYY-MM-DD HH:mm:ss') : null,
      day_of_week: reminder.frequency === ReminderFrequency.WEEKLY ? day : null,
      hour: [ReminderFrequency.DAILY, ReminderFrequency.WEEKLY].includes(reminder.frequency) ? hour : null,
      minute: [ReminderFrequency.DAILY, ReminderFrequency.WEEKLY].includes(reminder.frequency) ? minute : null,
    }
  }, [])
}
