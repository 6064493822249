import { Suspense } from 'react'

export const withLoader =
  <TProps extends object>(Component: React.FC<TProps>, fallback: React.ReactNode) =>
  (props: TProps) => {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    )
  }
