import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Language as LanguageCode } from '@/i18n'

import LanguagePicker from '@/components/Language'
import { useGetProject, useUpdateProject } from '@/projects/queries'
import { useGetMe } from '@/auth/queries'

export interface LanguageProps {}

export const Language: React.FC<LanguageProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings' })
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const { data: me } = useGetMe()
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: updateProject } = useUpdateProject(projectId, teamspaceId)

  return (
    <div className="relative flex items-center gap-2">
      <p className="w-36 text-sm">{t('notificationLanguage')}</p>
      <LanguagePicker
        disabled={me.uuid !== project?.owner_id}
        code={project.language_code || LanguageCode.EN}
        onChange={(code) => {
          updateProject({ language_code: code })
        }}
      />
    </div>
  )
}

export default Language
