import { PropsWithChildren, Suspense } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

export interface SkeletonProps {
  isActive?: boolean
  className?: string
}

export const SkeletonComponent: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        cx('w-12 animate-pulse rounded bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark', className),
      )}
    >
      {children}
    </div>
  )
}

export const Skeleton: React.FC<PropsWithChildren<SkeletonProps>> = ({ className, children, isActive }) => {
  if (isActive)
    return (
      <Suspense fallback={<SkeletonComponent className={className}>&nbsp;</SkeletonComponent>}>{children}</Suspense>
    )

  return <SkeletonComponent className={className}>&nbsp;</SkeletonComponent>
}

export default Skeleton
