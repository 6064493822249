// import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUtils } from '@telegram-apps/sdk-react'

// import Toggle from '@/components/Toggle'
import ComingSoon from '@/components/ComingSoon'

import { sendEvent } from '@/utils/hooks/useAmplitude'

export interface FeaturesProps {}

// type Inputs = {
//   backlog: boolean
//   sprints: boolean
// }

export const Features: React.FC<FeaturesProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.features' })
  const utils = useUtils()

  // const { watch, setValue } = useForm<Inputs>({
  //   defaultValues: {
  //     backlog: true,
  //     sprints: false,
  //   },
  // })

  return (
    <div className="grid gap-2">
      {/* <p className="text-sm">{t('title')}</p> */}
      <div
        className="flex cursor-pointer items-center justify-between gap-2"
        onClick={() => {
          sendEvent('open-backlog-arcticle')
          utils.openTelegramLink(t('backlogPostLink'))
        }}
      >
        <div className="flex flex-col gap-1">
          <p className="text-sm">{t('backlog')}</p>
          <p className="text-xs text-wall-gray-neutral">{t('backlogTip')}</p>
        </div>
        <ComingSoon />
      </div>
      <div
        className="flex cursor-pointer items-center justify-between gap-2"
        onClick={() => {
          sendEvent('open-sprints-arcticle')
          utils.openTelegramLink(t('sprintsPostLink'))
        }}
      >
        <div className="flex flex-col gap-1">
          <p className="text-sm">{t('sprints')}</p>
          <p className="text-xs text-wall-gray-neutral">{t('sprintsTip')}</p>
        </div>
        <ComingSoon />
      </div>
      {/* <Toggle
        label={t('backlog')}
        text={t('backlogTip')}
        id="backlog"
        checked={watch('backlog')}
        color="green"
        onChange={(value) => setValue('backlog', value)}
      />
      <Toggle
        label={t('sprints')}
        text={t('sprintsTip')}
        id="sprints"
        checked={watch('sprints')}
        color="green"
        onChange={(value) => setValue('sprints', value)}
      /> */}
    </div>
  )
}

export default Features
