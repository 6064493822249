import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import Select from '@/components/Select'

import { Priority as PriorityEnum } from '@/projects/models/IProject'
import { usePriorityOptions } from './hooks/usePriorityOptions'

export interface PriorityProps {
  priority?: PriorityEnum
  disabled?: boolean
  onChange?: (value: PriorityEnum) => void
}

export const Priority: React.FC<PriorityProps> = ({ priority, ...otherProps }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.priority' })

  const { options, priorities } = usePriorityOptions()

  const Button = useMemo(() => {
    return (props: React.ButtonHTMLAttributes<HTMLButtonElement> & { value?: PriorityEnum }) => {
      const selectedPriority = priorities.find((s) => s.id === props.value)
      return (
        <button
          className={cx('flex h-7 w-7 items-center justify-center rounded transition-colors', selectedPriority?.bg)}
          {...props}
        >
          {selectedPriority && <selectedPriority.Icon className={selectedPriority.iconColor} />}
        </button>
      )
    }
  }, [priority])

  return <Select value={priority} title={t('prioritySelectLabel')} options={options} Button={Button} {...otherProps} />
}

export default Priority
