import { Language } from '@/i18n'

export interface IUser {
  telegram_id: number | null
  username: string | null
  first_name: string | null
  last_name: string | null
  photo_url: string | null
  language_code: Language | null
  notifications: Record<string, boolean>
}

export interface IUserResponse extends IUser {
  uuid: string
  created_at: string
}

export enum UserType {
  regular = 'regular',
  external = 'external',
}

export interface IUserListItemResponse extends IUser {
  user_uuid: string
  created_at: string
  user_type: UserType
}
