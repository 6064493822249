import cx from 'clsx'
import React, { useMemo } from 'react'
// import { toast } from 'react-toastify'
import { useUtils } from '@telegram-apps/sdk-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, Drawer } from 'flowbite-react'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { useToggleOpen } from '@/utils/hooks/useToggle'
import { useGetProject, useInviteUser, usePatchTicket } from '@/projects/queries'
import { useGetMe } from '@/auth/queries'

import { UserType } from '@/auth/models/IUser'

export interface InviteProps {
  projectId?: string
  ticketId?: string
  onInvite?: () => void
  setInviteUuid?: (uuid: string) => void
}

export const Invite: React.FC<InviteProps> = ({
  onInvite,
  projectId: projectIdProps,
  ticketId: ticketIdProps,
  setInviteUuid,
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.assignee' })
  const {
    projectId: projectIdParams,
    ticketId: ticketIdParams,
    teamspaceId,
  } = useParams<{ projectId: string; teamspaceId: string; ticketId: string }>()

  const projectId = projectIdParams ?? projectIdProps
  const ticketId = ticketIdParams ?? ticketIdProps

  const { data: me } = useGetMe()
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: patchTicket } = usePatchTicket(teamspaceId, projectId, ticketId)

  const isIos = useIsIos()
  const utils = useUtils()

  const { mutateAsync: inviteUser } = useInviteUser(projectId, teamspaceId)

  const { isOpen: isInviteTypeOpen, toggleOpen: toggleInviteType } = useToggleOpen()

  const inviteOptions = useMemo(() => {
    return [
      {
        value: UserType.regular,
        label: t('regularLabel'),
      },
      {
        value: UserType.external,
        label: t('externalLabel'),
      },
    ]
  }, [t])

  const name = [me.first_name, me.last_name].filter(Boolean).join(' ')

  const handleInviteClick = (userType: UserType) => async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    const { invite_link: link, invite_uuid: inviteUuid } = await inviteUser({ user_type: userType })
    setInviteUuid?.(inviteUuid)

    if (ticketId && inviteUuid) {
      await patchTicket({
        invite_uuid: inviteUuid,
      })
    }

    toggleInviteType()

    // this is right format, please do not add tabulation and remove linebreaks
    utils.shareURL(
      `${t('inviteNotificationStart', { username: name, project: project.name })}

${t('inviteNotificationEnd', { username: name })}

${link}`,
    )

    // toast(t(ticketId ? 'inviteSentAndAssignedToast' : 'inviteSentToast'), { type: 'success' })
    onInvite?.()
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <Drawer open={isInviteTypeOpen} position="bottom" onClose={toggleInviteType} className={cx(isIos && 'pb-10')}>
        <Drawer.Header title={t('inviteUserAsTitle')} titleIcon={() => null} />
        <Drawer.Items className="mt-3 flex flex-col gap-2">
          {inviteOptions.map((option) => (
            <button
              key={option.value}
              className="w-full rounded bg-wall-main-bg-light p-2 text-left text-sm text-wall-main-light hover:bg-wall-main-light/10 dark:bg-wall-main-bg-dark dark:text-wall-main-dark dark:hover:bg-wall-main-dark/10"
              onClick={handleInviteClick(option.value)}
            >
              {option.label}
            </button>
          ))}
        </Drawer.Items>
      </Drawer>
      <Button
        onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          toggleInviteType()
        }}
        className="w-full"
        size="xs"
        color="gray"
      >
        {t('inviteLabel')}
      </Button>
    </div>
  )
}

export default Invite
