import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import Select from '@/components/Select'

import { filter } from '@/projects/store'
import { usePriorityOptions } from '@/components/Priority/hooks/usePriorityOptions'
import { useUserOptions } from '../UserSelect/hooks/useUserOptions'

export interface FilterProps {}

export const Filter: React.FC<FilterProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'filter' })
  const [filterValue, setFilter] = useAtom(filter)
  const { options: priorityOptions } = usePriorityOptions()
  const userOptions = useUserOptions()

  return (
    <div className="flex gap-2 px-3">
      <Select
        options={userOptions}
        isMulti
        placeholder={t('assigneeTitle')}
        title={t('assigneeTitle')}
        value={filterValue?.assignee_uuids}
        onChange={(assignee) => setFilter((filter) => ({ ...filter, assignee_uuids: assignee }))}
      />
      <Select
        options={userOptions}
        isMulti
        placeholder={t('creatorTitle')}
        title={t('creatorTitle')}
        value={filterValue?.owner_uuids}
        onChange={(owners) => setFilter((filter) => ({ ...filter, owner_uuids: owners }))}
      />
      <Select
        options={priorityOptions}
        isMulti
        placeholder={t('priorityTitle')}
        title={t('priorityTitle')}
        value={filterValue?.priority}
        onChange={(priority) => setFilter((filter) => ({ ...filter, priority }))}
      />
    </div>
  )
}

export default Filter
