import { useMemo, memo, useCallback } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { Button, Drawer } from 'flowbite-react'
import Select from '@/components/Select'
import { ArrowPathIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import DaysOfWeekSelector from '../DaysOfWeekSelector'

import { useToggleOpen } from '@/utils/hooks/useToggle'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'

import { leftPadTime } from '@/utils'

import { RecurringFrequency } from '@/projects/models/IProject'
import DaysOfMonthSelector from '../DaysOfMonthSelector'

export type RecurringValue = {
  frequency?: RecurringFrequency
  daysOfWeek?: number[]
  daysOfMonth?: (number | string)[]
  hour?: number
  minute?: number
}

export interface RecurringProps {
  recurring?: RecurringValue | null
  isNowAvailable?: boolean
  isSet?: boolean
  label?: string
  onChange?: (recurring: RecurringValue | null) => void
}

export const Recurring: React.FC<RecurringProps> = ({ recurring, isSet = true, label, onChange }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.recurring' })
  const { isOpen, toggleOpen } = useToggleOpen()

  const defaultValues = {
    frequency: recurring?.frequency || RecurringFrequency.WEEKLY,
    daysOfWeek: recurring?.daysOfWeek || [new Date().getDay()],
    daysOfMonth: recurring?.daysOfMonth || [new Date().getDate()],
    hour: recurring?.hour || new Date().getHours(),
    minute: recurring?.minute || new Date().getMinutes(),
  }

  const { register, setValue, watch, getValues, reset } = useForm<RecurringValue>({
    defaultValues,
  })
  register('frequency')
  register('daysOfWeek')
  register('daysOfMonth')

  const frequency = watch('frequency')

  const isIos = useIsIos()

  const FREQUENCY_OPTIONS = useMemo(
    () => [
      { value: RecurringFrequency.DAILY, label: t('frequency.daily') },
      { value: RecurringFrequency.WEEKLY, label: t('frequency.weekly') },
      { value: RecurringFrequency.MONTHLY, label: t('frequency.monthly') },
    ],
    [t],
  )

  const handleClose = useCallback(() => {
    reset(defaultValues)
    toggleOpen()
  }, [recurring])

  const HOURS_OPTIONS = useMemo(
    () => Array.from({ length: 24 }, (_, i) => ({ value: i, label: leftPadTime(`${i}`) })),
    [],
  )
  const MINUTES_OPTIONS = useMemo(
    () => Array.from({ length: 60 }, (_, i) => ({ value: i, label: leftPadTime(`${i}`) })),
    [],
  )

  return (
    <>
      <div className="flex flex-col gap-1">
        {label && <span className="text-xs">{label}</span>}
        <Button
          onClick={toggleOpen}
          size="xs"
          color={recurring?.frequency ? 'secondary' : 'gray'}
          type="button"
          className={twMerge(cx('[&>span]:items-center [&>span]:justify-between [&>span]:gap-1'))}
        >
          <ArrowPathIcon className="h-4 w-4" />
          {t('title')}
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </div>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        className={cx('rounded-t-md', {
          'pb-10': isIos,
        })}
        position="bottom"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Drawer.Header title={t('title')} titleIcon={() => null} />
        <div className="flex flex-col gap-3">
          <div className="flex flex-wrap gap-2">
            <Select
              options={FREQUENCY_OPTIONS}
              title={t('frequencyLabel')}
              label={t('frequencyLabel')}
              value={frequency}
              onChange={(value) => setValue('frequency', value)}
            />
            {frequency === RecurringFrequency.WEEKLY && (
              <DaysOfWeekSelector
                title={t('daysLabel')}
                label={t('daysLabel')}
                value={watch('daysOfWeek')}
                onChange={(value) => setValue('daysOfWeek', value)}
              />
            )}
            {frequency === RecurringFrequency.MONTHLY && (
              <DaysOfMonthSelector
                title={t('daysLabel')}
                label={t('daysLabel')}
                value={watch('daysOfMonth')}
                onChange={(value) => setValue('daysOfMonth', value)}
              />
            )}
            <div className="flex flex-col gap-1">
              <span className="text-xs">{t('timeLabel')}</span>
              <div className="flex items-center gap-1">
                <Select
                  options={HOURS_OPTIONS}
                  title={t('hourLabel')}
                  value={watch('hour')}
                  onChange={(value) => setValue('hour', value)}
                />
                :
                <Select
                  options={MINUTES_OPTIONS}
                  title={t('minuteLabel')}
                  value={watch('minute')}
                  onChange={(value) => setValue('minute', value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2">
            {isSet && (
              <Button
                size="xs"
                color="gray"
                type="button"
                onClick={() => {
                  onChange?.(null)
                  toggleOpen()
                }}
              >
                {t('deleteLabel')}
              </Button>
            )}
            <Button
              size="xs"
              color="green"
              type="button"
              onClick={() => {
                onChange?.(getValues())
                toggleOpen()
              }}
            >
              {t('saveLabel')}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default memo(Recurring)
