import { useMainButton } from '@telegram-apps/sdk-react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface SaveButtonProps {
  onSave: () => void
}

const useSaveButton = ({ onSave }: SaveButtonProps) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })
  const button = useMainButton()

  const hideSaveButton = useCallback(() => {
    button.hide()
  }, [button])

  const showSaveButton = useCallback(() => {
    button.show()
  }, [button])

  const setLoading = useCallback((isLoading: boolean) => {
    button.setParams({ isLoaderVisible: isLoading })
  }, [])

  useEffect(() => {
    button.setParams({ bgColor: '#FF805C', text: t('createLabel'), isEnabled: true })
    const removeClickHandler = button.on('click', onSave)

    showSaveButton()

    return () => {
      removeClickHandler()
      hideSaveButton()
    }
  }, [])

  return {
    hideSaveButton,
    showSaveButton,
    button,
    isVisible: button.isVisible,
    setLoading,
  }
}

export default useSaveButton
