import { useTranslation } from 'react-i18next'

export interface ComingSoonProps {}

export const ComingSoon: React.FC<ComingSoonProps> = () => {
  const { t } = useTranslation('common')
  return (
    <div className="whitespace-nowrap rounded bg-wall-brown-light px-2 py-1 text-center text-xs text-wall-brown-dark dark:bg-wall-brown-dark dark:text-wall-brown-light">
      {t('comingSoonLabel')}
    </div>
  )
}

export default ComingSoon
