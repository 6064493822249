import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useUtils } from '@telegram-apps/sdk-react'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'flowbite-react'

import Select, { Option } from '@/components/Select'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

import { useGetTeamspaceChats } from '@/teamspace/queries'
import { useGetProject, useUpdateProject } from '@/projects/queries'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { useGetMe } from '@/auth/queries'
import { useToggleOpen } from '@/utils/hooks/useToggle'

export interface ChatProps {
  showLabel?: boolean
  placeholder?: string
}

export const Chat: React.FC<ChatProps> = ({ showLabel = true, placeholder }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.chat' })
  const { teamspaceId, projectId } = useParams<{ teamspaceId: string; projectId: string }>()

  const [isUpdating, setIsUpdating] = useState(false)
  const { isOpen, setIsOpen } = useToggleOpen()

  const { data: me } = useGetMe()
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: updateProject } = useUpdateProject(projectId, teamspaceId)
  const {
    data: chats,
    refetch: refetchChats,
    isFetching: isFetchingChats,
  } = useGetTeamspaceChats(teamspaceId, { refetchOnWindowFocus: isUpdating })

  const [chatsLength, setChatsLength] = useState(chats.length)

  useEffect(() => {
    if (chats.length > chatsLength && isUpdating) {
      const latestChat = chats.reduce((prev, current) =>
        new Date(prev.updated_at).getTime() > new Date(current.updated_at).getTime() ? prev : current,
      )

      setIsUpdating(false)
      setChatsLength(chats.length)
      updateProject({ chat_id: latestChat.id })
      setIsOpen(false)
      sendEvent('attached-chat-to-project')
    }
  }, [chats.length, chats, chatsLength])

  const utils = useUtils()

  const options = useMemo(() => {
    const options: Option<number>[] = chats.map((chat) => ({ value: chat.id, label: chat.name }))

    options.unshift({
      value: 0,
      clickable: false,
      element: (
        <div className="flex w-full flex-col items-start gap-2">
          <div className="rounded bg-wall-main-light p-2 text-xs text-white dark:bg-wall-main-dark">
            <Trans t={t} i18nKey="topicTip" components={{ i: <i className="text-wall-blue" /> }} />
          </div>
          <Button
            size="xs"
            color="gray"
            onClick={() => {
              setIsUpdating(true)
              setTimeout(() => {
                setIsUpdating(false)
              }, 30000)
              utils.openTelegramLink(`https://t.me/${import.meta.env.VITE_BOT_NICKNAME}?startgroup=0&admin`)
            }}
          >
            {t('addToChatLabel')}
          </Button>
          <div className="flex w-full justify-between gap-2">
            <span className="text-sm text-wall-gray-neutral">{t('selectFromChatsLabel')}</span>
            <button
              className="rounded border p-0.5 transition-colors hover:border-wall-gray-neutral hover:text-wall-gray-neutral"
              type="button"
              disabled={isFetchingChats}
              onClick={() => {
                setIsUpdating(false)
                refetchChats()
              }}
            >
              <ArrowPathIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      ),
    })

    return options
  }, [chats])

  return (
    <div className="flex items-center gap-2">
      {showLabel && <p className="w-36 text-sm">{t('label')}</p>}

      <Select
        options={options}
        title={t('title')}
        open={isOpen}
        placeholder={placeholder || t('placeholder')}
        disabled={me.uuid !== project?.owner_id}
        value={project?.chat_id || undefined}
        isClearable
        onChange={async (value) => {
          await updateProject({
            chat_id: value || null,
          })
          sendEvent('attached-chat-to-project')
        }}
      />
    </div>
  )
}

export default Chat
