import { ToggleSwitch, ToggleSwitchProps } from 'flowbite-react'

export interface ToggleProps extends ToggleSwitchProps {
  label?: string
  text?: string
  id: string
}

export const Toggle: React.FC<ToggleProps> = ({ label, text, id, ...props }) => {
  return (
    <div className="flex items-center justify-between gap-4">
      <label htmlFor={id}>
        {label && <p className="text-sm">{label}</p>}
        {text && <p className="text-xs text-neutral-500">{text}</p>}
      </label>
      <ToggleSwitch id={id} color="green" {...props} />
    </div>
  )
}

export default Toggle
