import { Dispatch, SetStateAction } from 'react'
import { Button, TextInput } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useAtom } from 'jotai'

import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { useCreateTicket } from '@/projects/queries'
import { Priority, TicketType } from '@/projects/models/IProject'
import { columns as columnsAtom } from '@/projects/store'

export interface CreateCardProps {
  status: string
  isCreating: boolean
  isWide: boolean
  setIsCreating: Dispatch<SetStateAction<boolean>>
}

type Inputs = {
  title: string
}
const MAX_TITLE_LENGTH = 70
export const CreateCard: React.FC<CreateCardProps> = ({ status, isCreating, isWide, setIsCreating }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { t } = useTranslation('projects')

  const { mutateAsync: create, isPending } = useCreateTicket(teamspaceId, projectId)
  const [, setColumns] = useAtom(columnsAtom)

  const { register, reset, handleSubmit } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const reposponse = await create({
      description: '',
      status,
      title: data.title,
      type: TicketType.Task,
      assignee: null,
      due_date: null,
      start_date: null,
      priority: Priority.Medium,
      is_recurring: false,
      invite_uuid: null,
      reminder_settings: null,
      recurring_params: null,
    })
    const card = {
      id: reposponse.uuid,
      number: reposponse.ticket_id,
      priority: Priority.Medium,
      status,
      title: data.title,
      assignee: null,
      due_date: null,
    }
    setColumns((prev) => {
      const newColumns = [...prev]
      const index = newColumns.findIndex((column) => column.id === card.status)
      newColumns[index].cards.unshift(card)
      return newColumns
    })

    reset()
    setIsCreating(false)
  }

  return (
    <div
      className={twMerge(
        cx(
          'shadow-wall-main/20 rounded bg-wall-main-light shadow transition-colors dark:bg-wall-main-dark',
          isCreating && 'bg-wall-main-light/10 dark:bg-wall-main-dark/30',
          {
            'opacity-0 transition-opacity duration-300 group-hover:opacity-100': isWide && !isCreating,
          },
        ),
      )}
    >
      {!isCreating && (
        <button
          className="flex w-full items-center justify-center gap-2 px-3 py-2 text-white"
          onClick={() => {
            setIsCreating(true)
          }}
        >
          {t('ticket.createLabel')}
          <PlusCircleIcon className="h-6 w-6" />
        </button>
      )}

      {isCreating && (
        <form className="flex flex-col gap-2 p-3" onSubmit={handleSubmit(onSubmit)} id="create-ticket">
          <TextInput
            {...register('title', { required: true, minLength: 1, maxLength: MAX_TITLE_LENGTH })}
            maxLength={MAX_TITLE_LENGTH}
            placeholder={t('ticket.titlePlaceholder')}
            autoFocus
            data-amp-mask
          />
          <div className="flex justify-end gap-2">
            <Button
              size="xs"
              color="red"
              type="button"
              onClick={() => {
                setIsCreating(false)
              }}
            >
              {t('ticket.cancelLabel')}
            </Button>
            <Button
              size="xs"
              color="primary"
              type="submit"
              data-amplitude="create-ticket-from-board"
              disabled={isPending}
            >
              {t('ticket.saveLabel')}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}

export default CreateCard
